import Swiper from "swiper";
document.addEventListener("DOMContentLoaded", function () {
  const gallery = document.querySelector(".gallery .swiper");
  if (!gallery) return;

  const { backdrop, swiper } = init(gallery);

  clickOn(".gallery .swiper-slide img", (e) => openPopup(e, backdrop, swiper));
  clickOn(".image-backdrop.gallery-image", (e) => closePopup(e, backdrop));
});

function openPopup(e, backdrop, swiper) {
  console.log(e);
  try {
    const index =
      parseInt(
        e.target
          .closest(".swiper-slide")
          .getAttribute("aria-label")
          .split("/")[0]
          .trim()
      ) - 1;
    swiper.slideTo(index);

    setTimeout(() => {
      backdrop.classList.add("show");
    }, 200);
  } catch (e) {
    return false;
  }
}
function closePopup(e, backdrop) {
  console.log(e);
  backdrop.classList.remove("show");
  if (e.target === e.currentTarget) {
  }
}

function init(gallery) {
  const backdrop = document.createElement("div");
  const ctrls = document.querySelector(".ctrls-list").cloneNode(true);
  backdrop.classList.add("image-backdrop", "gallery-image");
  backdrop.innerHTML = `<div class="swiper-container"></div>`;
  backdrop
    .querySelector(".swiper-container")
    .appendChild(gallery.cloneNode(true));
  backdrop.querySelector(".swiper-container").appendChild(ctrls);

  backdrop.querySelector(".swiper").classList.remove("gallery-swiper");

  document.body.appendChild(backdrop);

  const swiper = new Swiper(".image-backdrop .swiper", {
    slidesPerView: 1,
    breakpoints: {
      992: {
        slidesPerView: 1.4,
      },
    },
    spaceBetween: 30,
    centeredSlides: true,
    navigation: {
      nextEl: ".image-backdrop  .next",
      prevEl: ".image-backdrop  .prev",
    },
  });
  return { backdrop, swiper };
}

function clickOn(element, callback) {
  list[element] = callback;
}
document.addEventListener("click", (event) => {
  Object.keys(list).forEach((selector) => {
    if (event.target.matches(selector)) {
      list[selector](event);
    }
  });
});
const list = {};
